<template>
  <div>
    <b-card>
      <b-row>

        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Başlangıç Tarihi"
            label-for="sdate"
          >
            <b-form-datepicker
              id="sdate"
              v-model="filterData.sdate"
              v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
              locale="tr"
              start-weekday="1"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Bitiş Tarihi"
            label-for="edate"
          >
            <b-form-datepicker
              id="edate"
              v-model="filterData.edate"
              v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
              locale="tr"
              start-weekday="1"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Lokasyon"
            label-for="id_com_location"
          >
            <v-select
              id="id_com_location"
              v-model="filterData.id_com_location"
              name="id_com_location"
              label="title"
              :reduce="location => location.id"
              :options="locations"
              placeholder="Lokasyon Seçin"
              multiple
              :close-on-select="false"
            />
          </b-form-group>

        </b-col>
        <b-col>
          <b-form-group
            label="Marka"
            label-for="id_com_brand"
          >
            <v-select
              id="id_com_brand"
              v-model="filterData.id_com_brand"
              name="id_com_brand"
              label="name"
              :reduce="brand => brand.id"
              :options="brands"
              placeholder="Marka Seçin"
              multiple
              :close-on-select="false"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="text-center"
        >
          <b-button
            variant="primary"
            :disabled="!filterData.sdate || !filterData.edate"
            @click="getReport"
          >
            <FeatherIcon icon="PieChartIcon" />
            Raporu Görüntüle
          </b-button>
        </b-col>
      </b-row>

    </b-card>
    <pre-loading v-if="loading" />
    <template v-if="report.data.length > 0">
      <b-card no-body>
        <b-card-header>
          <b-card-title>Durumlar</b-card-title>
        </b-card-header>
        <b-card-body>
          <b-row v-if="report.data.length > 0">
            <b-col
              v-for="(detail,key) in report.data"
              :key="key"
            >
              <div class="border p-2 text-center rounded bg-light mb-2">
                <div class="font-medium-3 font-weight-light text-nowrap">
                  {{ detail.title }}
                </div>
                <div class="font-weight-bolder font-large-1 text-primary">
                  {{ detail.count | toNumber }}
                </div>
              </div>
            </b-col>
          </b-row>
          <b-alert
            v-else
            show
          >
            <div class="alert-body text-center">
              Servis başvuru verisi bulunmamaktadır.
            </div>
          </b-alert>
        </b-card-body>
      </b-card>
      <b-card no-body>
        <b-card-header>
          <b-card-title>Başvuru Listesi</b-card-title>
          <b-button
            variant="primary"
            :disabled="!filterData.sdate || !filterData.edate"
            :href="downloadURLDurations"
            target="_blank"
          >
            <FeatherIcon icon="DownloadIcon" />
            Excel Raporu Al
          </b-button>
        </b-card-header>
        <b-table
          responsive="sm"
          :fields="fields"
          :items="report.details"
          striped
          fixed
        />
        <b-card-footer>
          <b-pagination
            v-model="currentPage"
            :total-rows="dataCount"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-card-footer></b-card>
    </template>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BFormGroup,
  BFormDatepicker,
  BButton,
  BAlert, BTable, BPagination, BCardFooter,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import PreLoading from '@/layouts/components/common/PreLoading.vue'

export default {
  name: 'TracingPriceRequests',
  components: {
    vSelect,
    BCardFooter,
    BPagination,
    BTable,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BFormGroup,
    BFormDatepicker,
    BButton,
    BAlert,
    PreLoading,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      fields: [
        {
          key: 'customer',
          label: 'Müşteri',
        },
        {
          key: 'feedback',
          label: 'Başvuru',
        },
        {
          key: 'brand',
          label: 'Marka',
        },
        {
          key: 'location',
          label: 'Lokasyon',
        },
        {
          key: 'meet',
          label: 'Temas Tipi',
        },
        {
          key: 'status',
          label: 'Durum',
        },
        {
          key: 'created',
          label: 'Başvuru Tarihi',
        },

      ],
    }
  },
  computed: {
    loading() {
      return this.$store.getters['TracingPriceRequests/getLoading']
    },

    report() {
      return this.$store.getters['TracingPriceRequests/getReport']
    },
    filterData() {
      return this.$store.getters['TracingPriceRequests/getFilterData']
    },
    meets() {
      return this.$store.getters['meets/getMeets']
    },
    locations() {
      return this.$store.getters['locations/getLocations']
    },
    brands() {
      return this.$store.getters['brands/getBrands']
    },
    dataCount() {
      return this.$store.getters['TracingPriceRequests/crmCounts']
    },
    downloadUrl() {
      return `${this.$store.state.app.baseURL}/exports/reports/crm/TracingPriceRequest/excel?token=${localStorage.getItem('downloadToken')}&sdate=${this.sdate}&edate=${this.edate}`
    },
    downloadURLDurations() {
      const urlParams2 = []
      if (this.filterData.sdate) {
        urlParams2.push(`sdate=${this.filterData.sdate}`)
      }
      if (this.filterData.edate) {
        urlParams2.push(`edate=${this.filterData.edate}`)
      }
      if (this.filterData.id_com_brand) {
        this.filterData.id_com_brand.forEach(e => {
          urlParams2.push(`id_com_brand[]=${e}`)
        })
      }
      if (this.filterData.id_com_location) {
        this.filterData.id_com_location.forEach(e => {
          urlParams2.push(`id_com_location[]=${e}`)
        })
      }
      const params = urlParams2.join('&')
      let url = `${this.$store.state.app.baseURL}/exports/reports/crm/TracingPriceRequest/excel?token=${localStorage.getItem('downloadToken')}`
      if (params) {
        url += `&${params}`
      }
      return url
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 20
      this.pagination(page)
    },
  },
  created() {
    // this.getMeets()
    this.getLocations()
    this.getBrands()
  },
  methods: {
    getReport() {
      this.$store.dispatch('TracingPriceRequests/report', this.filterData)
    },
    getMeets() {
      this.$store.dispatch('meets/meetsList')
    },
    getLocations() {
      this.$store.dispatch('locations/locationsList', {
        where: {
          'com_location.service_status': 1,
        },
      })
    },
    getBrands() {
      this.$store.dispatch('brands/brandsList', {
        select: [
          'com_brand.id AS id',
          'com_brand.name AS name',
        ],
        where: {
          'com_brand.servicestatus': 1,
        },
      })
    },
    pagination(page) {
      this.filterData.start = page
      if (this.filterData.start === page) {
        this.getReport()
      }
    },
  },

}
</script>
